import { FormikDropdown } from 'molecules/FormikDropdown';
import React from 'react';
import { injectIntl } from 'react-intl';
import { IBrandFeatures } from 'stateManagement/Quote/features';
import { IPropsFeatureComponentWithIntl } from '../types';

export const FINAL_CLEANING_OPTIONS = {
  NONE: 0,
  INCLUSIVE: 1,
  CONSUMPTION: 2,
  OBLIGATORY: 3,
  OPTIONAL: 4,
};
const DROPDOWN_OPTIONS = [
  {
    label: 'characterisitics.form.field.feature.common.option.none',
    value: FINAL_CLEANING_OPTIONS.NONE,
  },
  { label: 'Inclusive', value: FINAL_CLEANING_OPTIONS.INCLUSIVE },
  { label: 'Consumption', value: FINAL_CLEANING_OPTIONS.CONSUMPTION },
  { label: 'Obligatory', value: FINAL_CLEANING_OPTIONS.OBLIGATORY },
  { label: 'Optional', value: FINAL_CLEANING_OPTIONS.OPTIONAL },
];

const FinalCleaning = ({
  fieldName,
  labelKey,
  errors,
  touched,
  intl: { formatMessage },
}: IPropsFeatureComponentWithIntl) => (
  <FormikDropdown<IBrandFeatures>
    errors={errors}
    touched={touched}
    options={DROPDOWN_OPTIONS}
    label={formatMessage({ id: labelKey })}
    fieldName={fieldName}
  />
);

export default injectIntl(FinalCleaning);
