import { FormikDropdown } from 'molecules/FormikDropdown';
import React from 'react';
import { injectIntl } from 'react-intl';
import { IBrandFeatures } from 'stateManagement/Quote/features';
import { IPropsFeatureComponentWithIntl } from '../types';

export const ELECTRICITY_TYPES = {
  NONE: 0,
  INCLUDED_19_39: 1,
  INCLUDED: 2,
  INCLUDED_23_35: 3,
  ELEC_WATER_INCL: 6,
  WATER_INCLUDED: 7,
  HEATING_INCL: 8,
  CONSUMPTION_INCL: 9,
};

export const ELECTRICITY_DROPDOWN_OPTIONS = [
  {
    label: 'characterisitics.form.field.feature.common.option.none',
    value: ELECTRICITY_TYPES.NONE,
  },
  {
    label:
      'characterisitics.form.field.feature.energy_costs_included.option.electri_inkl_in_week_no_1939',
    value: ELECTRICITY_TYPES.INCLUDED_19_39,
  },
  {
    label: 'characterisitics.form.field.feature.energy_costs_included.option.electricity_included',
    value: ELECTRICITY_TYPES.INCLUDED,
  },
  {
    label:
      'characterisitics.form.field.feature.energy_costs_included.option.electri_inkl_in_week_no_2335',
    value: ELECTRICITY_TYPES.INCLUDED_23_35,
  },
  {
    label:
      'characterisitics.form.field.feature.energy_costs_included.option.electricity_and_water_incl',
    value: ELECTRICITY_TYPES.ELEC_WATER_INCL,
  },
  {
    label: 'characterisitics.form.field.feature.energy_costs_included.option.water_included',
    value: ELECTRICITY_TYPES.WATER_INCLUDED,
  },
  {
    label: 'characterisitics.form.field.feature.energy_costs_included.option.heating_incl',
    value: ELECTRICITY_TYPES.HEATING_INCL,
  },
  {
    label:
      'characterisitics.form.field.feature.energy_costs_included.option.consumption_costs_incl',
    value: ELECTRICITY_TYPES.CONSUMPTION_INCL,
  },
];

const Electricity = ({
  fieldName,
  labelKey,
  errors,
  touched,
  values,
  intl: { formatMessage },
}: IPropsFeatureComponentWithIntl) => (
  <FormikDropdown<IBrandFeatures>
    errors={errors}
    touched={touched}
    options={ELECTRICITY_DROPDOWN_OPTIONS}
    label={formatMessage({ id: labelKey })}
    fieldName={fieldName}
    disabled={values ? values.isAllInclusive : false}
  />
);

export default injectIntl(Electricity);
