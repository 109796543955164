import { FormikDropdown } from 'molecules/FormikDropdown';
import React from 'react';
import { injectIntl } from 'react-intl';
import { IBrandFeatures } from 'stateManagement/Quote/features';
import { IPropsFeatureComponentWithIntl } from '../types';

export const INTERNET_OPTIONS = {
  NONE: 0,
  VIA_CABLE: 52,
  CABLE: 53,
  AGAINST_PAYMENT: 63,
  WIRELESS: 64,
};

export const INTERNET_DROPDOWN_OPTIONS = [
  { label: 'characterisitics.form.field.feature.common.option.none', value: INTERNET_OPTIONS.NONE },
  {
    label: 'characterisitics.form.field.feature.internet.option.wireless_internet',
    value: INTERNET_OPTIONS.WIRELESS,
  },
  {
    label: 'characterisitics.form.field.feature.internet.option.cable_internet_against_payment',
    value: INTERNET_OPTIONS.CABLE,
  },
  {
    label: 'characterisitics.form.field.feature.internet.option.internet_access_via_cable_incl',
    value: INTERNET_OPTIONS.VIA_CABLE,
  },
  {
    label: 'characterisitics.form.field.feature.internet.option.wireless_internet_ag_payment',
    value: INTERNET_OPTIONS.AGAINST_PAYMENT,
  },
];

const Internet = ({
  fieldName,
  labelKey,
  errors,
  touched,
  intl: { formatMessage },
}: IPropsFeatureComponentWithIntl) => (
  <FormikDropdown<IBrandFeatures>
    errors={errors}
    touched={touched}
    options={INTERNET_DROPDOWN_OPTIONS}
    label={formatMessage({ id: labelKey })}
    fieldName={fieldName}
  />
);

export default injectIntl(Internet);
