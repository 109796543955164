import { FormikDropdown } from 'molecules/FormikDropdown';
import React from 'react';
import { injectIntl } from 'react-intl';
import { IBrandFeatures } from 'stateManagement/Quote/features';
import { IPropsFeatureComponentWithIntl } from '../types';

export const BALCONY_TYPES = {
  NONE: 0,
  OPEN_TERRACE: 1,
  COVERED_TERRACE: 2,
  OPEN_COVERED_TERRACE: 3,
  BALCONY_TERRACE: 4,
  BALCONY: 5,
  PORCH: 6,
  PARTLY_COVERED_TERRACE: 7,
  SHARED_TERRACE_BALCONY: 8,
  TERRACE_BALCONY: 9,
};

export const BALCONY_DROPDOWN_OPTIONS = [
  {
    label: 'characterisitics.form.field.feature.common.option.none',
    value: BALCONY_TYPES.NONE,
  },
  {
    label: 'characterisitics.form.field.feature.terrace_balcony.option.open_terrace',
    value: BALCONY_TYPES.OPEN_TERRACE,
  },
  {
    label: 'characterisitics.form.field.feature.terrace_balcony.option.covered_terrace',
    value: BALCONY_TYPES.COVERED_TERRACE,
  },
  {
    label: 'characterisitics.form.field.feature.terrace_balcony.option.open_and_covered_terrace',
    value: BALCONY_TYPES.OPEN_COVERED_TERRACE,
  },
  {
    label: 'characterisitics.form.field.feature.terrace_balcony.option.balcony_and_terrace',
    value: BALCONY_TYPES.BALCONY_TERRACE,
  },
  {
    label: 'characterisitics.form.field.feature.terrace_balcony.option.balcony',
    value: BALCONY_TYPES.BALCONY,
  },
  {
    label: 'characterisitics.form.field.feature.terrace_balcony.option.porch',
    value: BALCONY_TYPES.PORCH,
  },
  {
    label: 'characterisitics.form.field.feature.terrace_balcony.option.partly_covered_terrace',
    value: BALCONY_TYPES.PARTLY_COVERED_TERRACE,
  },
  {
    label: 'characterisitics.form.field.feature.terrace_balcony.option.shared_terracebalcony',
    value: BALCONY_TYPES.SHARED_TERRACE_BALCONY,
  },
  {
    label: 'characterisitics.form.field.feature.terrace_balcony.option.terracebalcony',
    value: BALCONY_TYPES.TERRACE_BALCONY,
  },
];

const Pooltype = ({
  fieldName,
  labelKey,
  errors,
  touched,
  intl: { formatMessage },
}: IPropsFeatureComponentWithIntl) => (
  <FormikDropdown<IBrandFeatures>
    errors={errors}
    touched={touched}
    options={BALCONY_DROPDOWN_OPTIONS}
    label={formatMessage({ id: labelKey })}
    fieldName={fieldName}
  />
);

export default injectIntl(Pooltype);
