import { FormikDropdown } from 'molecules/FormikDropdown';
import React from 'react';
import { injectIntl } from 'react-intl';
import { IBrandFeatures } from 'stateManagement/Quote/features';
import { IPropsFeatureComponentWithIntl } from '../types';

export const VIEW_TYPES = {
  NONE: 0,
  SEA_VIEW: 1,
  RESTRICTED_SEA_VIEW: 2,
  PANORAMIC_WATER: 3,
  SEA_VIEW_FIRST: 4,
  SEA_VIEW_TERRACE: 5,
  SEA_VIEW_PLOT: 6,
  DUNES: 7,
  INLET: 8,
};

export const VIEW_DROPDOWN_OPTIONS = [
  { label: 'characterisitics.form.field.feature.common.option.none', value: VIEW_TYPES.NONE },
  {
    label: 'characterisitics.form.field.feature.view_type.option.sea_view',
    value: VIEW_TYPES.SEA_VIEW,
  },
  {
    label: 'characterisitics.form.field.feature.view_type.option.restricted_seaview',
    value: VIEW_TYPES.RESTRICTED_SEA_VIEW,
  },
  {
    label: 'characterisitics.form.field.feature.view_type.option.panoramic_view_over_water',
    value: VIEW_TYPES.PANORAMIC_WATER,
  },
  {
    label: 'characterisitics.form.field.feature.view_type.option.sea_view_from_1st_floor',
    value: VIEW_TYPES.SEA_VIEW_FIRST,
  },
  {
    label: 'characterisitics.form.field.feature.view_type.option.sea_view_from_the_terrace',
    value: VIEW_TYPES.SEA_VIEW_TERRACE,
  },
  {
    label: 'characterisitics.form.field.feature.view_type.option.sea_view_from_the_plot',
    value: VIEW_TYPES.SEA_VIEW_PLOT,
  },
  {
    label: 'characterisitics.form.field.feature.view_type.option.view_of_the_dunes',
    value: VIEW_TYPES.DUNES,
  },
  {
    label: 'characterisitics.form.field.feature.view_type.option.view_of_inletfiord',
    value: VIEW_TYPES.INLET,
  },
];

const Viewtype = ({
  fieldName,
  labelKey,
  errors,
  touched,
  intl: { formatMessage },
}: IPropsFeatureComponentWithIntl) => (
  <FormikDropdown<IBrandFeatures>
    errors={errors}
    touched={touched}
    options={VIEW_DROPDOWN_OPTIONS}
    label={formatMessage({ id: labelKey })}
    fieldName={fieldName}
  />
);

export default injectIntl(Viewtype);
