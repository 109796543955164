import { FormikDropdown } from 'molecules/FormikDropdown';
import React from 'react';
import { injectIntl } from 'react-intl';
import { IBrandFeatures } from 'stateManagement/Quote/features';
import { IPropsFeatureComponentWithIntl } from '../types';

export const FISH_HOUSE_TYPES = {
  NONE: 'none',
  FISH_HOUSE: 'Fish house',
  FISH_HOUSE_COAST: 'Fish house plus Coast',
  FISH_HOUSE_INLAND: 'Fish house plus Inland',
};

export const FISH_HOUSE_DROPDOWN_OPTIONS = [
  { label: 'characterisitics.form.field.feature.common.option.none', value: FISH_HOUSE_TYPES.NONE },
  {
    label: 'characterisitics.form.field.feature.fish_house.option.fish_house',
    value: FISH_HOUSE_TYPES.FISH_HOUSE,
  },
  {
    label: 'characterisitics.form.field.feature.fish_house.option.fish_house_plus_coast',
    value: FISH_HOUSE_TYPES.FISH_HOUSE_COAST,
  },
  {
    label: 'characterisitics.form.field.feature.fish_house.option.fish_house_plus_inland',
    value: FISH_HOUSE_TYPES.FISH_HOUSE_INLAND,
  },
];

const Fishhouse = ({
  fieldName,
  labelKey,
  errors,
  touched,
  intl: { formatMessage },
}: IPropsFeatureComponentWithIntl) => (
  <FormikDropdown<IBrandFeatures>
    errors={errors}
    touched={touched}
    options={FISH_HOUSE_DROPDOWN_OPTIONS}
    label={formatMessage({ id: labelKey })}
    fieldName={fieldName}
  />
);

export default injectIntl(Fishhouse);
