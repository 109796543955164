import { TextField } from '@material-ui/core';

import { FastFieldProps } from 'formik';
import React from 'react';

interface IProps extends FastFieldProps {
  label?: string | null;
  error?: string | null;
  helperText?: string | null;
  className?: string;
  margin?: 'none' | 'dense' | 'normal';
  classes?: object;
  type?: string;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  disabled?: boolean;
  Component: React.ComponentType<any>;
  value: any;
  onChange: (e: React.ChangeEvent<any>) => void;
  inputProps?: object;
}

const Input: React.FunctionComponent<IProps> = ({
  error,
  field,
  Component = TextField,
  ...otherProps
}) => {
  if (error) {
    otherProps.helperText = error;
  }

  return (
    <Component variant="outlined" margin="normal" error={!!error} {...field} {...otherProps} />
  );
};

export default Input;
