import { FormikDropdown } from 'molecules/FormikDropdown';
import React from 'react';
import { injectIntl } from 'react-intl';
import { IBrandFeatures } from 'stateManagement/Quote/features';
import { IPropsFeatureComponentWithIntl } from '../types';

export const AC_OPTIONS = {
  NONE: 'none',
  AIR_CON: 'Air conditioning',
  MOB_AIR_CON: 'Mobile air conditioning',
  AIR_CON_H_C: 'Air conditioning cold/hot',
  FULL_AIR_CON: 'Fully airconditioned',
  AIR_CON_PAYED: 'Aircondition against payment',
};
export const AC_DROPDOWN_OPTIONS = [
  {
    label: 'characterisitics.form.field.feature.common.option.none',
    value: AC_OPTIONS.NONE,
  },
  {
    label: 'characterisitics.form.field.feature.air_conditioning.option.air_conditioning',
    value: AC_OPTIONS.AIR_CON,
  },
  {
    label: 'characterisitics.form.field.feature.air_conditioning.option.mobile_air_conditioning',
    value: AC_OPTIONS.MOB_AIR_CON,
  },
  {
    label: 'characterisitics.form.field.feature.air_conditioning.option.air_conditioning_coldhot',
    value: AC_OPTIONS.AIR_CON_H_C,
  },
  {
    label: 'characterisitics.form.field.feature.air_conditioning.option.fully_airconditioned',
    value: AC_OPTIONS.FULL_AIR_CON,
  },
  {
    label:
      'characterisitics.form.field.feature.air_conditioning.option.aircondition_against_payment',
    value: AC_OPTIONS.AIR_CON_PAYED,
  },
];

const AC = ({
  fieldName,
  labelKey,
  errors,
  touched,
  intl: { formatMessage },
}: IPropsFeatureComponentWithIntl) => (
  <FormikDropdown<IBrandFeatures>
    errors={errors}
    touched={touched}
    options={AC_DROPDOWN_OPTIONS}
    label={formatMessage({ id: labelKey })}
    fieldName={fieldName}
  />
);

export default injectIntl(AC);
