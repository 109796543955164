import { FormikDropdown } from 'molecules/FormikDropdown';
import React from 'react';
import { injectIntl } from 'react-intl';
import { IBrandFeatures } from 'stateManagement/Quote/features';
import { IPropsFeatureComponentWithIntl } from '../types';

export const DC_OPTIONS = {
  NONE: 'none',
  SPAR: 'spar',
  LONG_TERM: 'longterm stay',
};
export const DC_DROPDOWN_OPTIONS = [
  { label: 'characterisitics.form.field.feature.common.option.none', value: DC_OPTIONS.NONE },
  {
    label: 'quote.form.field.discountConcept.option.spar',
    value: DC_OPTIONS.SPAR,
  },
  {
    label: 'quote.form.field.discountConcept.option.long_term',
    value: DC_OPTIONS.LONG_TERM,
  },
];

const DC = ({
  fieldName,
  labelKey,
  errors,
  touched,
  intl: { formatMessage },
}: IPropsFeatureComponentWithIntl) => (
  <FormikDropdown<IBrandFeatures>
    errors={errors}
    touched={touched}
    options={DC_DROPDOWN_OPTIONS}
    label={formatMessage({ id: labelKey })}
    fieldName={fieldName}
  />
);

export default injectIntl(DC);
