import { FormikDropdown } from 'molecules/FormikDropdown';
import React from 'react';
import { injectIntl } from 'react-intl';
import { IBrandFeatures } from 'stateManagement/Quote/features';
import { IPropsFeatureComponentWithIntl } from '../types';

export const SAUNA_TYPES = {
  NONE: 0,
  SAUNA: 1,
  SOLARIUM: 2,
  TANNING_BED: 3,
  OUTDOOR: 4,
  SHARED: 5,
};

export const SAUNA_DROPDOWN_OPTIONS = [
  { label: 'characterisitics.form.field.feature.common.option.none', value: SAUNA_TYPES.NONE },
  { label: 'characterisitics.form.field.feature.sauna.option.sauna', value: SAUNA_TYPES.SAUNA },
  {
    label: 'characterisitics.form.field.feature.sauna.option.solarium',
    value: SAUNA_TYPES.SOLARIUM,
  },
  {
    label: 'characterisitics.form.field.feature.sauna.option.sauna_and_tanning_bed',
    value: SAUNA_TYPES.TANNING_BED,
  },
  {
    label: 'characterisitics.form.field.feature.sauna.option.outdoor_sauna',
    value: SAUNA_TYPES.OUTDOOR,
  },
  {
    label: 'characterisitics.form.field.feature.sauna.option.shared_sauna',
    value: SAUNA_TYPES.SHARED,
  },
];

const Sauna = ({
  fieldName,
  labelKey,
  errors,
  touched,
  intl: { formatMessage },
}: IPropsFeatureComponentWithIntl) => (
  <FormikDropdown<IBrandFeatures>
    errors={errors}
    touched={touched}
    options={SAUNA_DROPDOWN_OPTIONS}
    label={formatMessage({ id: labelKey })}
    fieldName={fieldName}
  />
);

export default injectIntl(Sauna);
