import { FormikDropdown } from 'molecules/FormikDropdown';
import React from 'react';
import { injectIntl } from 'react-intl';
import { IBrandFeatures } from 'stateManagement/Quote/features';
import { IPropsFeatureComponentWithIntl } from '../types';

export const GERMAN_TV_OPTIONS = {
  NONE: 0,
  SATELLITE: 1,
  CABLE: 2,
  SATELLITE_GERMAN: 3,
  SATELLITE_SCAND: 4,
  SATELLITE_GERMAN_SCAND: 5,
  CABLE_GERMAN: 6,
  CABLE_SCAND: 7,
  CABLE_GERMAN_SCAND: 8,
  GERMAN: 9,
};

export const GERMAN_TV_DROPDOWN_OPTIONS = [
  {
    label: 'characterisitics.form.field.feature.common.option.none',
    value: GERMAN_TV_OPTIONS.NONE,
  },
  {
    label: 'characterisitics.form.field.feature.german_tv.option.satellite',
    value: GERMAN_TV_OPTIONS.SATELLITE,
  },
  {
    label: 'characterisitics.form.field.feature.german_tv.option.cable_tv',
    value: GERMAN_TV_OPTIONS.CABLE,
  },
  {
    label: 'characterisitics.form.field.feature.german_tv.option.satellite_german_channels',
    value: GERMAN_TV_OPTIONS.SATELLITE_GERMAN,
  },
  {
    label: 'characterisitics.form.field.feature.german_tv.option.satellite_scandic_channels',
    value: GERMAN_TV_OPTIONS.SATELLITE_SCAND,
  },
  {
    label: 'characterisitics.form.field.feature.german_tv.option.satellite_german_and_scand',
    value: GERMAN_TV_OPTIONS.SATELLITE_GERMAN_SCAND,
  },
  {
    label: 'characterisitics.form.field.feature.german_tv.option.cable_tv_german_channels',
    value: GERMAN_TV_OPTIONS.CABLE_GERMAN,
  },
  {
    label: 'characterisitics.form.field.feature.german_tv.option.cable_tv_scand_channels',
    value: GERMAN_TV_OPTIONS.CABLE_SCAND,
  },
  {
    label: 'characterisitics.form.field.feature.german_tv.option.cable_tv_german_and_scand',
    value: GERMAN_TV_OPTIONS.CABLE_GERMAN_SCAND,
  },
  {
    label: 'characterisitics.form.field.feature.german_tv.option.german_tv',
    value: GERMAN_TV_OPTIONS.GERMAN,
  },
];

const GermanTv = ({
  fieldName,
  labelKey,
  errors,
  touched,
  intl: { formatMessage },
}: IPropsFeatureComponentWithIntl) => (
  <FormikDropdown<IBrandFeatures>
    errors={errors}
    touched={touched}
    options={GERMAN_TV_DROPDOWN_OPTIONS}
    label={formatMessage({ id: labelKey })}
    fieldName={fieldName}
  />
);

export default injectIntl(GermanTv);
