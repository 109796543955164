import { DialogContent, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';

interface IProps {
  open: boolean;
  isDataUpdate: boolean;
}

export const UpdateRequiredModal = ({ open, isDataUpdate }: IProps) => {
  function closeModalAndRefresh() {
    window.location.replace(window.origin);
  }
  return (
    <Dialog aria-labelledby="update-modal-title" open={open} maxWidth="xs" fullWidth>
      {isDataUpdate ? (
        <React.Fragment>
          <DialogTitle disableTypography id="update-modal-title">
            <Typography align="center" variant="h2">
              IPT data update available
            </Typography>
          </DialogTitle>

          <DialogContent>
            <Typography align="left" variant="body1">
              Please note this update may take several minutes to complete
            </Typography>
          </DialogContent>
        </React.Fragment>
      ) : (
        <DialogTitle disableTypography id="update-modal-title">
          <Typography align="center" variant="h2">
            IPT update available
          </Typography>
        </DialogTitle>
      )}
      <DialogActions>
        <Button onClick={closeModalAndRefresh} color="primary">
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};
