import { Checkbox, FormControlLabel } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import { FieldProps } from 'formik';
import React from 'react';

interface IProps extends FieldProps {
  label: string;
  size?: 'small' | 'large';
  onChange?: (event: React.ChangeEvent<any>) => void;
}

const Input: React.FunctionComponent<IProps> = ({
  label,
  size = 'large',
  field,
  form,
  onChange,
  ...otherProps
}) => {
  const handleChange = (event: React.ChangeEvent<any>) => {
    if (onChange) {
      onChange(event);
    }
    field.onChange(event);
  };

  return (
    <FormControlLabel
      control={
        <Checkbox
          icon={<CheckBoxOutlineBlankIcon fontSize={size} />}
          checkedIcon={<CheckBoxIcon fontSize={size} />}
          color="primary"
          checked={field.value}
          {...field}
          {...otherProps}
          onChange={handleChange}
        />
      }
      label={label}
    />
  );
};

export default Input;
