import { FormControl, InputLabel, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { DropdownContainer, IOptions } from 'constants/dropdownOptions';
import { Field, FormikErrors, FormikTouched } from 'formik';
import { Select } from 'molecules/Input';
import React from 'react';
import { FormattedMessage, InjectedIntlProps, injectIntl } from 'react-intl';
import { IUUIDName } from 'services/appDatabase';
import { formatDropdownOptions } from 'services/utils/dropdown/formatDropdownOptions';
import { isRequired } from 'services/validation';
import { colorPalette } from 'stylesheet';

export interface IFormikDropdownProps<T> {
  errors: FormikErrors<T>;
  touched: FormikTouched<T>;
  rawOptions?: IUUIDName[];
  options?: IOptions[];
  fieldName: keyof T;
  label: string;
  className?: string;
  disabled?: boolean;
  shouldTranslate?: boolean;
  validate?: (value: any) => string | Promise<string | undefined> | undefined;
  onChange?: (event: React.ChangeEvent<any>) => void;
}

const useStyles = makeStyles({
  red: {
    color: colorPalette.red,
  },
});

const FormikDropdown = <T extends {}>(props: InjectedIntlProps & IFormikDropdownProps<T>) => {
  const {
    errors,
    touched,
    rawOptions,
    fieldName,
    label,
    className,
    options,
    disabled,
    validate,
    onChange,
    shouldTranslate = true,
    intl: { formatMessage },
  } = props;

  const validationFunction = validate !== undefined ? validate : isRequired(formatMessage);
  const shouldDisplayError = !!touched[fieldName] && !!errors[fieldName];

  const inputLabel = React.useRef<HTMLLabelElement>(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    setLabelWidth(inputLabel.current!.offsetWidth);
  }, []);

  const classes = useStyles();
  const formattedOptions = options || formatDropdownOptions(rawOptions);

  return (
    <DropdownContainer className={className}>
      <FormControl variant="outlined">
        <InputLabel
          ref={inputLabel}
          htmlFor={`${fieldName}`}
          className={shouldDisplayError ? classes.red : ''}
        >
          {label}
        </InputLabel>
        <Field
          id={fieldName}
          name={fieldName}
          validate={validationFunction}
          component={Select}
          labelWidth={labelWidth}
          error={shouldDisplayError && errors[fieldName]}
          disabled={disabled}
          onChange={onChange}
        >
          {formattedOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {shouldTranslate ? <FormattedMessage id={option.label} /> : option.label}
            </MenuItem>
          ))}
        </Field>
      </FormControl>
    </DropdownContainer>
  );
};

export default injectIntl(FormikDropdown) as unknown as <T extends {}>(
  props: IFormikDropdownProps<T>,
) => any;
