import { FormHelperText, OutlinedInput, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { FieldProps } from 'formik';
import React from 'react';
import { colorPalette } from 'stylesheet';

interface IProps extends FieldProps {
  label: string;
  labelWidth: number;
  onChange: (event: React.ChangeEvent<any>) => void;
  error?: string | null;
}

const useStyles = makeStyles({
  red: {
    color: colorPalette.red,
  },
});

// This is a wrapper to use the MaterialUI.Select component with Formik
// You shouldn't use this component but FormikDropdown instead

const CustomSelect: React.FunctionComponent<IProps> = ({
  label,
  field,
  form,
  labelWidth,
  children,
  error,
  onChange,
  ...otherProps
}) => {
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<any>) => {
    if (onChange) {
      onChange(event);
    }
    field.onChange(event);
  };

  return (
    <>
      <Select
        input={<OutlinedInput labelWidth={labelWidth} name={field.name} error={!!error} />}
        {...field}
        {...otherProps}
        onChange={handleChange}
        error={!!error}
        aria-describedby="component-error-text"
      >
        {children}
      </Select>
      {!!error && (
        <FormHelperText className={classes.red} id="component-error-text">
          {error}
        </FormHelperText>
      )}
    </>
  );
};

CustomSelect.defaultProps = {
  labelWidth: 0,
};

export default CustomSelect;
