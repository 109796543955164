import { FormikDropdown } from 'molecules/FormikDropdown';
import React from 'react';
import { injectIntl } from 'react-intl';
import { IBrandFeatures } from 'stateManagement/Quote/features';
import { IPropsFeatureComponentWithIntl } from '../types';

export const POOL_TYPES = {
  NONE: 0,
  SHARED_INDOOR: 1,
  INDOOR: 2,
  OUTDOOR: 3,
  WATERPARK_ENTRANCE: 4,
  WATERPARK_POOL_ACCESS: 5,
  COUNTERCURRENT: 6,
  WATERPARK_POOL_ACCESS_OUTDOOR: 7,
  PADDLING: 8,
  SHARED_OUTDOOR: 9,
};

export const POOL_DROPDOWN_OPTIONS = [
  { label: 'characterisitics.form.field.feature.common.option.none', value: POOL_TYPES.NONE },
  {
    label: 'characterisitics.form.field.feature.pool_type.option.shared_indoor_swimming_pool',
    value: POOL_TYPES.SHARED_INDOOR,
  },
  {
    label: 'characterisitics.form.field.feature.pool_type.option.indoor_swimming_pool',
    value: POOL_TYPES.INDOOR,
  },
  {
    label: 'characterisitics.form.field.feature.pool_type.option.outdoor_swimming_pool',
    value: POOL_TYPES.OUTDOOR,
  },
  {
    label: 'characterisitics.form.field.feature.pool_type.option.free_entrance_to_waterpark',
    value: POOL_TYPES.WATERPARK_ENTRANCE,
  },
  {
    label: 'characterisitics.form.field.feature.pool_type.option.free_access_to_waterparkpool',
    value: POOL_TYPES.WATERPARK_POOL_ACCESS,
  },
  {
    label: 'characterisitics.form.field.feature.pool_type.option.pool_w_countercurrent_system',
    value: POOL_TYPES.COUNTERCURRENT,
  },
  {
    label: 'characterisitics.form.field.feature.pool_type.option.free_accwaterparkoutd_pool',
    value: POOL_TYPES.WATERPARK_POOL_ACCESS_OUTDOOR,
  },
  {
    label: 'characterisitics.form.field.feature.pool_type.option.paddling_pool',
    value: POOL_TYPES.PADDLING,
  },
  {
    label: 'characterisitics.form.field.feature.pool_type.option.shared_outdoor_swimming_pool',
    value: POOL_TYPES.SHARED_OUTDOOR,
  },
];

const Pooltype = ({
  fieldName,
  labelKey,
  errors,
  touched,
  intl: { formatMessage },
}: IPropsFeatureComponentWithIntl) => (
  <FormikDropdown<IBrandFeatures>
    errors={errors}
    touched={touched}
    options={POOL_DROPDOWN_OPTIONS}
    label={formatMessage({ id: labelKey })}
    fieldName={fieldName}
  />
);

export default injectIntl(Pooltype);
