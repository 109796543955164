import { FormikDropdown } from 'molecules/FormikDropdown';
import React from 'react';
import { injectIntl } from 'react-intl';
import { IBrandFeatures } from 'stateManagement/Quote/features';
import { IPropsFeatureComponentWithIntl } from '../types';

export const WHIRLPOOL_TYPES = {
  NONE: 0,
  WHIRLPOOL_BATH: 1,
  HOT_TUB: 2,
  WHIRLPOOL_BATH_OUTDOOR: 3,
  WHIRLPOOL_BATH_INDOOR_OUTDOOR: 4,
  HOT_TUB_FINNISH: 5,
  HOT_TUB_INDOOR_OUTDOOR: 6,
  HOT_TUB_OUTDOOR: 7,
  WHIRLPOOL_BATH_HOT_TUB: 8,
  WHIRLPOOL_INDOOR_HOT_TUB_OUTDOOR: 9,
};

export const WHIRLPOOL_DROPDOWN_OPTIONS = [
  { label: 'characterisitics.form.field.feature.common.option.none', value: WHIRLPOOL_TYPES.NONE },
  {
    label: 'characterisitics.form.field.feature.whirlpool.option.whirlpool_bath',
    value: WHIRLPOOL_TYPES.WHIRLPOOL_BATH,
  },
  {
    label: 'characterisitics.form.field.feature.whirlpool.option.hot_tub',
    value: WHIRLPOOL_TYPES.HOT_TUB,
  },
  {
    label: 'characterisitics.form.field.feature.whirlpool.option.outdoor_whirlpool_bath',
    value: WHIRLPOOL_TYPES.WHIRLPOOL_BATH_OUTDOOR,
  },
  {
    label: 'characterisitics.form.field.feature.whirlpool.option.in_and_outdoor_whirlpool_bath',
    value: WHIRLPOOL_TYPES.WHIRLPOOL_BATH_INDOOR_OUTDOOR,
  },
  {
    label: 'characterisitics.form.field.feature.whirlpool.option.finnish_hot_tub',
    value: WHIRLPOOL_TYPES.HOT_TUB_FINNISH,
  },
  {
    label: 'characterisitics.form.field.feature.whirlpool.option.in_and_outdoor_hot_tub',
    value: WHIRLPOOL_TYPES.HOT_TUB_INDOOR_OUTDOOR,
  },
  {
    label: 'characterisitics.form.field.feature.whirlpool.option.outdoor_hot_tub',
    value: WHIRLPOOL_TYPES.HOT_TUB_OUTDOOR,
  },
  {
    label: 'characterisitics.form.field.feature.whirlpool.option.whirlpool_bath_and_hot_tub',
    value: WHIRLPOOL_TYPES.WHIRLPOOL_BATH_HOT_TUB,
  },
  {
    label: 'characterisitics.form.field.feature.whirlpool.option.ind_whirlpbathoutd_hot_tub',
    value: WHIRLPOOL_TYPES.WHIRLPOOL_INDOOR_HOT_TUB_OUTDOOR,
  },
];

const Whirlpool = ({
  fieldName,
  labelKey,
  errors,
  touched,
  intl: { formatMessage },
}: IPropsFeatureComponentWithIntl) => (
  <FormikDropdown<IBrandFeatures>
    errors={errors}
    touched={touched}
    options={WHIRLPOOL_DROPDOWN_OPTIONS}
    label={formatMessage({ id: labelKey })}
    fieldName={fieldName}
  />
);

export default injectIntl(Whirlpool);
