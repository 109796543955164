import { FormikDropdown } from 'molecules/FormikDropdown';
import React from 'react';
import { injectIntl } from 'react-intl';
import { IBrandFeatures } from 'stateManagement/Quote/features';
import { IPropsFeatureComponentWithIntl } from '../types';

export const PLOT_TYPES = {
  NONE: 0,
  FENCED_GARDEN: 1,
  PRIVATE_GARDEN: 2,
  PRIVATE_FENCED_GARDEN: 3,
  OPEN_SITE: 4,
  FENCED_PLOT: 5,
  SECLUDED_PLOT: 6,
};

export const PLOT_DROPDOWN_OPTIONS = [
  { label: 'characterisitics.form.field.feature.common.option.none', value: PLOT_TYPES.NONE },
  {
    label: 'characterisitics.form.field.feature.plot_type.option.fenced_garden',
    value: PLOT_TYPES.FENCED_GARDEN,
  },
  {
    label: 'characterisitics.form.field.feature.plot_type.option.private_garden',
    value: PLOT_TYPES.PRIVATE_GARDEN,
  },
  {
    label: 'characterisitics.form.field.feature.plot_type.option.private_fenced_garden',
    value: PLOT_TYPES.PRIVATE_FENCED_GARDEN,
  },
  {
    label: 'characterisitics.form.field.feature.plot_type.option.open_site',
    value: PLOT_TYPES.OPEN_SITE,
  },
  {
    label: 'characterisitics.form.field.feature.plot_type.option.fenced_plot',
    value: PLOT_TYPES.FENCED_PLOT,
  },
  {
    label: 'characterisitics.form.field.feature.plot_type.option.secluded_plot',
    value: PLOT_TYPES.SECLUDED_PLOT,
  },
];

const Pooltype = ({
  fieldName,
  labelKey,
  errors,
  touched,
  intl: { formatMessage },
}: IPropsFeatureComponentWithIntl) => (
  <FormikDropdown<IBrandFeatures>
    errors={errors}
    touched={touched}
    options={PLOT_DROPDOWN_OPTIONS}
    label={formatMessage({ id: labelKey })}
    fieldName={fieldName}
  />
);

export default injectIntl(Pooltype);
